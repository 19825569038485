/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500&family=Josefin+Sans:ital,wght@0,100;0,200;1,300&family=Lato:wght@100;300;700&family=Nothing+You+Could+Do&family=Nunito+Sans:wght@300&display=swap');

@font-face {
  font-family: 'Zapfino Extra LT';
  src: url('~src/assets/fonts/ZapfinoExtraLT-Two.woff2') format('woff2'),
  url('~src/assets/fonts/ZapfinoExtraLT-Two.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Zapfino Extra LT';
  src: url('~src/assets/fonts/ZapfinoExtraLT-One.woff2') format('woff2'),
  url('~src/assets/fonts/ZapfinoExtraLT-One.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Zapfino Extra LT Pro';
  src: url('~src/assets/fonts/ZapfinoExtraLTPro.woff2') format('woff2'),
  url('~src/assets/fonts/ZapfinoExtraLTPro.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Zapfino Forte LT Pro';
  src: url('~src/assets/fonts/ZapfinoForteLTPro.woff2') format('woff2'),
  url('~src/assets/fonts/ZapfinoForteLTPro.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}


body {
  background-color: #f2f2f2 !important;
}

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

p, h1, h2, h3, h4, h5, h6, span, strong, b, ul > li {
  font-family: 'Josefin Sans', 'Lato', 'Nunito', sans-serif;
}

.lato-font, .lato-font * {
  font-family: "Lato", "Nunito", sans-serif;
}

.nycd-font, .nycd-font * {
  font-family: "Nothing You Could Do", "Lato", "Nunito", sans-serif;
  font-weight: 400;
}

.zapfino-font, .zapfino-font * {
  font-family: "Zapfino Extra LT", Zapfino, "Lato", "Nunito", sans-serif;
  font-weight: 400;
}

.no-bullet {
  list-style-type: none;
  text-align: left;
}

ion-toolbar {
  padding-left: 10px;
  padding-right: 10px;
}

div.logo {
  background-image: url("./assets/images/logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 25.5rem;
  height: 25.5rem;
}

div.logo-cotti-pizza {
  background-image: url("./assets/images/cottis-pizza.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 25.5rem;
  height: 25.5rem;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spotify-text {
  //min-width: 50em;
  max-width: 50em;
  min-height: 25em;
  max-height: 25em;
  overflow: hidden;
  overflow-y: scroll;
  text-align: right;
  font-family: "Lato", "Nunito", sans-serif;
  font-weight: 600;
}


.left {
  position: fixed;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
}

.right {
  position: fixed;
  top: 50%;
  right: 10%;
  transform: translate(-50%, -50%);
}

.footer {
  width: 100%;
  height: 10vh;
  padding-right: 30px;
  padding-left: 30px;
  position: absolute;
  bottom: 0;
}

.center {
  margin: 0 auto;
}

.dragscroll {
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  width: fit-content;
  margin: 40px auto;

  span {
    margin: 0 20px;
  }
}

.margin-top-1 {
  margin-top: 8rem;
}

.scroll {
  max-height: 21em;
  overflow: hidden;
  overflow-y: scroll;
  padding-top: 15px;
  padding-bottom: 15px;
}

.spotify ion-icon {
  color: #1DB954 !important;
}

.linkedin ion-icon {
  color: #2867B2 !important;
}

.facebook ion-icon {
  color: #3b5998 !important;
}

.instagram ion-icon {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  border-radius: 4px;
  color: #f2f2f2 !important;
}

a, .link {
  text-decoration: none;
  color: rgba(42, 42, 42, 0.91);
  font-weight: 700;
}

*.tiny-text {
  font-size: 12px;
}

*.small-text {
  font-size: 16px;
}


.safe-top {
  margin-top: var(--ion-safe-area-top);
}

.safe-bottom {
  margin-top: var(--ion-safe-area-bottom);
}

.footer-md::before {
  content: unset;
}

div {
  &.divider {
    background-image: url("assets/images/divider.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-origin: content-box;
    height: 1.5rem;
    width: auto;

    &.deg180 {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }


}

ion-footer {
  background-color: #fff;
}

p {
  font-size: 21px;
}


ion-row.container {
  margin: 0 auto;
  max-width: 60vw;

  p {
    font-size: 14px;
  }
}

div.container {
  margin: 40px auto;
  max-width: 60vw;

  .ion-text-center {
    margin: 20px auto;
    max-width: 30vw;
  }
}

ion-icon {
  cursor: pointer;
}

@media (prefers-color-scheme: dark) {
  ion-footer {
    background-color: #3f3f3f;
  }
}

@media all and (max-width: 769px) {
  *.small-text {
    font-size: 12px;
  }
  *.tiny-text {
    font-size: 8px;
  }
}

.zapfino-font, .zapfino-font * {
  line-height: 2rem;
  font-size: 8vw;
}

@media all and (max-width: 769px) {
  div.logo {
    margin: 0 auto;
    width: 12.5rem;
    height: 12.5rem;
  }
  p {
    font-size: 14px;
  }
  div.container {
    margin: 40px auto;
    max-width: 80vw;

    .ion-text-center {
      margin: 20px auto;
      max-width: 90vw !important;
    }
  }
  ion-row.container {
    p {
      font-size: 12px;
    }
  }

  .zapfino-font, .zapfino-font * {
    line-height: 2rem;
    font-size: 16vw;
  }

}


.mr-1 {
  margin-right: 1rem !important;
}
